import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useCallback,
} from "react";
import jwt_decode from "jwt-decode";
import { useNavigate, useSearchParams } from "react-router-dom";

import axios from "axios";
import { Predicates } from "../libraries/predicates/predicates";

interface AppContextInterface {
  authTokens: { refresh: string; access: string } | null;
  setAuthTokens?: any;
  user: any;
  username: string | null;
  setUser?: any;
  loginUser?: any;
  logoutUser?: any;
  hasPermission?: any;
}

const AuthContext = createContext<AppContextInterface | null>(null);

export default AuthContext;

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams();
  
  const cookieSessionId = useMemo(() => {
    const sharedCookie = document.cookie.replace("; ", "&");
    return process.env.NODE_ENV === "development"
      ? searchParams.get("sessid")
      : new URLSearchParams(sharedCookie).get("sharedsession");
  }, []);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() => {
    const tokens = localStorage.getItem("authTokens");
    return tokens ? JSON.parse(tokens) : null;
  });

  const [user, setUser] = useState(() => {
    const tokens = localStorage.getItem("authTokens");
    return tokens ? jwt_decode(tokens) : null;
  });

  const [username, setUsername] = useState(() => {
    const username = localStorage.getItem("username");
    return username ? JSON.parse(username) : null;
  });

  const [permissions, setPermissions] = useState(() => {
    const permissions = localStorage.getItem("permissions");
    return permissions ?? null;
  });

  const hasPermission = useCallback(
    (permisssionToCheck: string) => {
      return permissions?.includes(permisssionToCheck);
    },
    [permissions]
  );

  const loginUser = useCallback(async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_BASE_URL}/token/session/`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          sessid: cookieSessionId,
        }),
      });
      const data = await response.data;
      if (response.status === 200) {
        setAuthTokens(data);
        setUser(jwt_decode(data.access));
        setPermissions(data.permissions);
        setUsername(data.username);
        localStorage.setItem("authTokens", JSON.stringify(data));
        localStorage.setItem("permissions", JSON.stringify(data.permissions));
        localStorage.setItem("username", JSON.stringify(data.username));
      } else {
        logoutUser();
      }
    } catch (err) {
      logoutUser();
    }
  }, []);

  const logoutUser = useCallback((reroute = true) => {
    setAuthTokens(null);
    setUser(null);
    setUsername(null);
    setPermissions(null);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("permissions");
    localStorage.removeItem("username");
    if (reroute) {
      navigate("/no-access");
    }
  }, []);

  const contextData: AppContextInterface = useMemo(
    () => ({
      authTokens,
      setAuthTokens,
      user,
      username,
      setUser,
      loginUser,
      logoutUser,
      hasPermission,
    }),
    [authTokens, hasPermission, loginUser, logoutUser, user, username]
  );

  useEffect(() => {
    if (Predicates.isNotNullAndNotUndefinedAndNotEmpty(cookieSessionId)) {
      loginUser();
    } else {
      logoutUser();
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
